//file that contains all the data for LeBleu

{/* EVERYTHING REGARDING LE BLEU FORM FIELDS GO HERE */}
const leBleuItem = {
    name_en: '',
    name_ar: '',
    price: '',
    category: '',
    sub_category: '',
    description_en: '',
    description_ar: '',
    add_ons: [],
    hide: '',
    image: '',
    old_image: '',
}

const leBleuFieldErrors = {
    name_err: '',
    name_ar_err: '',
    price_err: '',
    category_err: '',
    sub_category_err: '',
    add_on_price_err: '',

}

const leBleuTriggerFieldError = {
    name_err_bool: false,
    name_ar_err_bool: false,
    price_err_bool: false,
    add_on_price_err_bool: false,
    category_err_bool: false,
    sub_category_err_bool: false,
}


const leBleuCategoryList = [
    {
        value: "BREAKFAST",
        label: "BREAKFAST",
    },
    {
        value: "A LA CARTE",
        label: "A LA CARTE",
    },

    {
        value: "BOUTIQUE",
        label: "BOUTIQUE",
    }
]

const leBleuSubCategories = {
    "breakfast": ["EGGS", "TOASTS", "PUDDING & YOGURT"],
    "A LA CARTE": ["SOUP", "SALAD", "HOT STARTER", "SANDWICHES", "MAIN COURSE", "PASTA & RISOTTO", "DESSERT", "CAKES", "MOCKTAILS", "SOFT DRINKS", "PREMIUM TEA", "PREMIUM COFFEE"],
    "BOUTIQUE": ["SOURDOUGH", "BAGUETTE", "DANISH", "CROISSANT", "SCONES"]
}

export {
    leBleuItem,
    leBleuFieldErrors,
    leBleuTriggerFieldError,
    leBleuCategoryList,
    leBleuSubCategories,

}

