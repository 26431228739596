

function AddDashboard({}) {
    return (
        <>
        this page will be used for adding an item to the menu
        </>
    )
}

export default AddDashboard;