const breakfast = [
    {
        name: "PLAIN CROISSANT ",
        description: "",
        price_en: "15",
        name_ar: "ﻛﺮواﺳﺎن ﺳﺎدة ",
        description_ar: "",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/Breakfast-11-plain_croissant.jpg",
    },

    {
        name: "PALESTINIAN ZAATAR CROISSANT  ",
        description: "",
        price_en: "17",
        name_ar: "ﻛﺮواﺳﺎن ﺑﺎﻟﺰﻋﺘﺮ اﻟﻔﻠﺴﻄﻴﻨﻲ",
        description_ar: "",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/03 WCS - All Day Breakfast - Palestinian Zaatar Croissant Labneh Olive Oil-1-WEB.jpg",
    },

    {
        name: "CHEESE PAIN SUISSE ",
        description: "",
        price_en: "20",
        name_ar: "ﻛﺮواﺳﺎن ﺳﻮﻳﺴﺮي ﺑﺎﻟﺠﺒﻦ ",
        description_ar: "",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/03 WCS - All Day Breakfast - Cheese Pain Suisse-1-WEB.jpg",
    },

    {
        name: "BACON & CHEESE PAIN SUISSE ",
        description: "",
        price_en: "23",
        name_ar: "ﻛﺮواﺳﺎن ﺳﻮﻳﺴﺮي ﺑﻠﺤﻢ اﻟﺒﻘﺮ اﻟﻤﻘﺪد ",
        description_ar: "",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/05 WCS - All Day Breakfast - Pain Suisse Cheese Bacon-WEB.jpg",
    },

    {
        name: "TURKEY & CHEESE PAIN SUISSE ",
        description: "",
        price_en: "23",
        name_ar: "ﻛﺮواﺳﺎن ﺳﻮﻳﺴﺮي ﺑﺎﻟﺘﺮﻛﻲ",
        description_ar: "",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/05 WCS - All Day Breakfast - Pain Suisse Cheese Turkey-WEB.jpg",
    },

    {
        name: "PAIN AU CHOCOLAT ",
        description: "MILK CHOCOLATE GANACHE, COCOA NIBS",
        price_en: "23",
        name_ar: "ﻛﺮواﺳﺎن اﻟﺸﻮﻛﻮﻻﺗﺔ ",
        description_ar: "ﻏﺎﻧﺎش ﺷﻮﻛﻮﻻﺗﺔ اﻟﺤﻠﻴﺐ، ﺣﺒﻴﺒﺎت اﻟﻜﺎﻛﺎو",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/06 WCS - All Day Breakfast - Pain Au Chocolat-1-WEB.jpg",
    },

    {
        name: "MAAMOUL CROISSANT, VANILLA ICING ",
        description: "",
        price_en: "15",
        name_ar: "",
        description_ar: "",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/07 WCS - All Day Breakfast - Maamoul Croissant Vanilla Icing-WEB.jpg",
    },

    {
        name: "GREEK YOGO ",
        description: "GREEK YOGURT, GRANOLA, COCONUT, HONEY",
        price_en: "35",
        name_ar: "",
        description_ar: "ﻟﺒﻦ ﻳﻮﻧﺎﻧﻲ، ﻏﺮاﻧﻮﻻ، ﺟﻮز اﻟﻬﻨﺪ اﻟﻄﺎزج، ﻋﺴﻞ",
        img_path: "/wcs-menu-designs-aspire/All Day Breakfast WEB 2/08 WCS - All Day Breakfast - Greek Yogo-1-WEB.jpg",
    },
]

export default breakfast;