const breakfast = [
    {
        name: "BREAKFAST PLATE ",
        description: " SEEDED RYE BREAD, COMTE CHEESE, SEASONAL JAM, CACIO E PEPE BUTTER, BOILED EGG",
        price_en: "45",
        name_ar: " ﻃﺒﻖ اﻹﻓﻄﺎر",
        description_ar: " ﺧﺒﺰ اﻟﺠﺎودار، ﺟﺒﻦ ﻛﻮﻣﺘﻲ، ﻣﺮﺑﻰ ﻣﻮﺳﻤﻲ، زﺑﺪة ﻛﺎﺳﻴﻮ إي ﺑﻴﺒﻲ، ﺑﻴﻀﺔ ﻣﺴﻠﻮﻗﺔ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/01 WCS - All Day Breakfast - Breakfast Plate-1-WEB.jpg",
    },

    {
        name: "CROISSANT, BUTTER, JAM ",
        description: "",
        price_en: "20",
        name_ar: " ﻛﺮواﺳﺎن، اﻟﺰﺑﺪة واﻟﻤﺮﺑﻰ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/02 WCS - All Day Breakfast - Croissant Butter Jam-1-WEB.jpg",
    },

    {
        name: "PALESTINIAN ZAATAR CROISSANT, LABNEH, OLIVE OIL ",
        description: "",
        price_en: "21",
        name_ar: " ﻛﺮواﺳﺎن ﺑﺎﻟﺰﻋﺘﺮ اﻟﻔﻠﺴﻄﻴﻨﻲ, زﻋﺘﺮ، ﻟﺒﻨﺔ، زﻳﺖ زﻳﺘﻮن",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/03 WCS - All Day Breakfast - Palestinian Zaatar Croissant Labneh Olive Oil-1-WEB.jpg",
    },

    {
        name: "CHEESE PAIN SUISSE ",
        description: "",
        price_en: "20",
        name_ar: " ﻛﺮواﺳﺎن ﺳﻮﻳﺴﺮي ﺑﺎﻟﺠﺒﻦ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/04 WCS - All Day Breakfast - Cheese Pain Suisse-1-WEB.jpg",
    },

    {
        name: "BACON & CHEESE PAIN SUISSE ",
        description: "",
        price_en: "23",
        name_ar: " ﻛﺮواﺳﺎن ﺳﻮﻳﺴﺮي ﺑﻠﺤﻢ اﻟﺒﻘﺮ اﻟﻤﻘﺪد ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/05 WCS - All Day Breakfast - Pain Suisse Cheese Bacon-WEB.jpg",
    },

    {
        name: "TURKEY & CHEESE PAIN SUISSE ",
        description: "",
        price_en: "23",
        name_ar: " ﻛﺮواﺳﺎن ﺳﻮﻳﺴﺮي ﺑﺎﻟﺘﺮﻛﻲ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/06 WCS - All Day Breakfast - Pain Suisse Cheese Turkey-WEB.jpg",
    },

    {
        name: "MUSHROOM CAESAR DANISH, SHREDDED LETTUCE ",
        description: "",
        price_en: "21",
        name_ar: " داﻧِﺶ ﺑﺎﻟﻔﻄﺮ و ﺳﻴﺰار, ﺟﺮﺟﻴﺮ و ﺧﺲ ﺻﻐﻴﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/07 WCS - All Day Breakfast - Mushroom Caesar Danish Shredded Lettuce-1-WEB.jpg",
    },

    {
        name: "PAIN AU CHOCOLAT ",
        description: "",
        price_en: "23",
        name_ar: " ﻛﺮواﺳﺎن اﻟﺸﻮﻛﻮﻻﺗﺔ ",
        description_ar: " ﻏﺎﻧﺎش ﺷﻮﻛﻮﻻﺗﺔ اﻟﺤﻠﻴﺐ، ﺣﺒﻴﺒﺎت اﻟﻜﺎﻛﺎو",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/08 WCS - All Day Breakfast - Pain Au Chocolat-1-WEB.jpg",
    },

    {
        name: "MAAMOUL CROISSANT, VANILLA ICING ",
        description: "",
        price_en: "15",
        name_ar: " ﻛﺮواﺳﺎن اﻟﻤﻌﻤﻮل, ﻛﺮﻳﻤﺔ اﻟﻔﺎﻧﻴﻠﻴﺎ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/09 WCS - All Day Breakfast - Maamoul Croissant Vanilla Icing-WEB.jpg",
    },

    {
        name: "GREEK YOGO ",
        description: "",
        price_en: "35",
        name_ar: " ﻳﻮﻏﻮ ﻳﻮﻧﺎﻧﻲ",
        description_ar: " ﻟﺒﻦ ﻳﻮﻧﺎﻧﻲ، ﻏﺮاﻧﻮﻻ، ﺟﻮز اﻟﻬﻨﺪ اﻟﻄﺎزج، ﻋﺴﻞ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/10 WCS - All Day Breakfast - Greek Yogo-1-WEB.jpg",
    },

    {
        name: "EGG MUFFIN ",
        description: "",
        price_en: "23",
        name_ar: " ﻣﻔﻦ اﻟﺒﻴﺾ",
        description_ar: " ﻛﺎﺳﺘﺮد اﻟﺒﻴﺾ، ﺟﺒﻨﺔ اﻟﺸﻴﺪر، زﺑﺪة ﻛﺎﺳﻴﻮ إي ﺑﻴﺒﻲ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/11 WCS - All Day Breakfast - Egg Muffin-1-WEB.jpg",
    },

    {
        name: "EGG DUKKAH MUFFIN ",
        description: "",
        price_en: "21",
        name_ar: " ﻣﻔﻦ اﻟﺒﻴﺾ واﻟﺪﻗﺔ ",
        description_ar: " ﺑﻴﺾ ﻣﻘﻠﻲ، ﻟﺒﻨﺔ، دﻗﺔ، زﻋﺘﺮ، زﻳﺖ اﻟﻬﺮﻳﺴﺔ",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/12 WCS - All Day Breakfast - Egg Dukkah Muffin-2-WEB.jpg",
    },

    {
        name: "AVOCADO TOAST ",
        description: "RYE BREAD, SALT, PEPPER, OLIVE OIL, LIME ADD EGG +6 QR",
        price_en: "31",
        name_ar: " ﺗﻮﺳﺖ ﺑﺎﻷﻓﻮﻛﺎدو ",
        description_ar: " ﺧﺒﺰ اﻟﺠﺎودار واﻷﻓﻮﻛﺎدو وﺑﺬور ﻋﺒﺎد اﻟﺸﻤﺲ + ٦ ر.ق",
        img_path: "/wcs-menu-designs-westbay/All Day Breakfast WEB 2/WCS - All Day Breakfast - Avocado Toast-1-WEB.jpg",
    },
]

export default breakfast;