const coffee = [
    {
        name: "AMERICANO ",
        description: " MEDIUM / LAERGE ",
        price_en: "M17 / L17",
        name_ar: " أﻣﺮﻳﻜﺎﻧﻮ",
        description_ar: " ﻣﺘﻮﺳﻂ / ﻛﺒﻴﺮ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-15-americano.jpg",
    },

    {
        name: "LATTE ",
        description: "MEDIUM / LARGE ",
        price_en: "M21 / L24",
        name_ar: " ﻻﺗﻴﻪ",
        description_ar: " ﻣﺘﻮﺳﻂ / ﻛﺒﻴﺮ  ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-13-latte.jpg",
    },

    {
        name: "CAPPUCCINO ",
        description: "MEDIUM / LARGE  ",
        price_en: "M21 / L24",
        name_ar: " ﻛﺎﺑﺘﺸﻴﻨﻮ",
        description_ar: " ﻣﺘﻮﺳﻂ / ﻛﺒﻴﺮ ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-14-cappuccino.jpg",
    },

    {
        name: "CAFE CON LECHE ",
        description: "MEDIUM / LARGE  ",
        price_en: "M24 / L27",
        name_ar: " ",
        description_ar: " ﻣﺘﻮﺳﻂ / ﻛﺒﻴﺮ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-12-cafe_con_leche.jpg",
    },

    {
        name: "V60 ",
        description: "  ",
        price_en: "24",
        name_ar: " ﻓﻲ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-03-v60.jpg",
    },

    {
        name: "CORTADO ",
        description: "  ",
        price_en: "19",
        name_ar: " ﻛﻮرﺗﺎدو",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-04-cortado.jpg",
    },

    {
        name: "MACCHIATO ",
        description: "  ",
        price_en: "19",
        name_ar: " ﻣﺎﻛﻴﺎﺗﻮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/.jpg",
    },

    {
        name: "ESPRESSO ",
        description: "  ",
        price_en: "14",
        name_ar: " إﺳﺒﺮﻳﺴﻮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-01-espresso.jpg",
    },

    {
        name: "DOPPIO ",
        description: "  ",
        price_en: "17",
        name_ar: " دوﺑﻴﻮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-09-doppio.jpg",
    },

    {
        name: "FLAT WHITE  ",
        description: "  ",
        price_en: "21",
        name_ar: " ﻓﻼت واﻳﺖ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-02-flat_white.jpg",
    },

    {
        name: "MATCHA LATTE ",
        description: "  ",
        price_en: "27",
        name_ar: " ﻣﺎﺗﺸﺎ ﻻﺗﻴﻪ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-07-matcha_latte.jpg",
    },

    {
        name: "MARBLE LATTE ",
        description: "  ",
        price_en: "27",
        name_ar: " مربل ﻣﺎﺗﺸﺎ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-06-marble_latte.jpg",
    },

    {
        name: "COLD BREW COFFEE ",
        description: "  ",
        price_en: "24",
        name_ar: " ﻛﻮﻟﺪ ﺑﺮو",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-05-cold_brew_coffee.jpg",
    },

    {
        name: "MOCHA ",
        description: "  ",
        price_en: "27",
        name_ar: " ﻣﻮﻛﺎ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-11-mocha.jpg",
    },

    {
        name: "HOT CHOCOLATE ",
        description: "  ",
        price_en: "24",
        name_ar: " ﻫﻮت ﺷﻮﻛﻮﻻﺗﺔ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/beverages/Coffee-10-hot_chocolate.jpg",
    },
]

export default coffee;