import styled from "styled-components"
import {Box, Grid, Typography, Paper} from "@mui/material"; //Paper is used to style the components items
import {styled as muiStyled} from "@mui/material/styles";
import {motion} from "framer-motion";
import { Link } from "react-router-dom";

function RestaurantsList({}) {

    const restaurantsList = [
        {
            name: "le-bleu",
            src: "/lsd_pics/work_pics/Lsd-Website-Home-Images-05.jpg",
        },

        {
            name: "white-coffee-spot",
            src: "/lsd_pics/work_pics/Lsd-Website-Home-Images-01.jpg",

        }
    ]

    //animation configuration object
    const fadeInAnimationVariants =  {
        initial: {
            opacity: 0,
            y: 100,
        },
        animate: (index) => ({
            opacity: 1,
            y: 0,
            transition: {
                delay: 0.05 * index,
            }
        }),
    }


    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                minHeight: '100vh',
            }}>
                <Box
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        width: '80%',
                        padding: '2',
                    }}>
                        <Grid container spacing = {2} justifyContent="center" alignItems="center">
                            {restaurantsList.map(({name, src}, index) => (
                                    <Grid xs={6}>
                                        <motion.div
                                            key= {index}
                                            variants={fadeInAnimationVariants}
                                            initial="initial"
                                            whileInView="animate"
                                            viewport={{
                                                once: true,
                                            }}
                                            custom={index}
                                        >
                                             <ImageContainerStyled>
                                                <Link to = {`${name}`}>
                                                    <img src = {src} alt = {name}  />
                                                </Link> 
                                            </ImageContainerStyled>
                                        </motion.div>
                                    </Grid>
                            ))}
                        </Grid>
                    </Box>
            </Box>
        
    )
}

const ImageContainerStyled = styled.div`
    position: relative;
    transition: 300ms;
    
    img {
        width: 100%;
        border-radius: 10px;
    }

    &:hover {
        scale: 1.1;
    }
`


export default RestaurantsList