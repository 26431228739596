import styled from "styled-components";
import { MainCategoryContainerStyled, CategoryContainerStyled, CategoryHeaderStyled, CategoryHeaderArStyled,  CategorySubHeaderStyled, CategorySubHeaderArStyled, CategoryGridStyled, ItemContainerStyled, ItemImageContainer, ItemBoxTwoStyled, ItemDetailsContainer, ItemDetailsStyled, ItemDetailsArStyled,  ItemPriceContainer, ItemPriceContainerAr, ItemNameStyled, ItemNameArStyled } from "../wcsMenuAspire/generalStyles";



function AddOnsGrid({triggerLanguage}) {
    if(triggerLanguage == "en") {
        return (
            <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                        ADD ONS
                    <TeaStyled>
                        <div>
                            <span>EXTRA COFFEE SHOT / 8 QR</span>
                        </div>
                        <div>
                            <span>ADD AVOCADO / 17 QR</span>
                        </div>
                        <div>
                            <span>ADD EGG / 6 QR</span>
                        </div>
                        <div>
                            <span>ADD CHEESE / 10 QR</span>
                        </div>
                        <div>
                            <span>ADD TURKEY / 9 QR</span>
                        </div>
                    </TeaStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }

    if(triggerLanguage == "ar") {
        return (
            <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                    <CategoryHeaderArStyled>
                    الإضافات
                    </CategoryHeaderArStyled>
                </CategoryContainerStyled>
                <TeaArStyled>
                <div>
                     <span>شوت قهوة اضافية / 8 ر.ق</span>
                </div> 
                <div>
                     <span>إﺿﺎﻓﺔ اﻷﻓﻮﻛﺎدو  / 17 ر.ق</span>
                </div> 
                <div>
                     <span>ﺑﻴﻀﺔ إﺿﺎﻓﻴﺔ  / 6 ر.ق</span>
                </div> 
                <div>
                     <span>ﺟﺒﻨﺔ إﺿﺎﻓﻴﺔ  / 10 ر.ق</span>
                </div> 
                <div>
                     <span>دﻳﻚ روﻣﻲ إﺿﺎﻓﻲ  / 9 ر.ق</span>
                </div> 
                </TeaArStyled>
            </MainCategoryContainerStyled>
        )
    }
}

const TeaStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`

const TeaArStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    text-align: right;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`

export default AddOnsGrid;