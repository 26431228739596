const salads = [
    {
        name: "QUINOA SALAD ",
        description: "WHITE QUINOA, CUCUMBER, MINT, KALAMATA OLIVE, FETA CHEESE, CHERRY TOMATO, HONEY MUSTARD DRESSING",
        price_en: "33",
        name_ar: "ﺳﻠﻄﺔ اﻟﻜﻴﻨﻮا ",
        description_ar: "اﻟﻜﻴﻨﻮا اﻟﺒﻴﻀﺎء ﺧﻴﺎر، ﻧﻌﻨﺎع،رﻣﺎن، زﻳﺘﻮن ﻛﺎﻻﻣﺎﺗﺎ، ﺟﺒﻨﺔ ﻓﻴﺘﺎ، ﻃﻤﺎﻃﻢ ﻛﺮزﻳﺔ ﺣﻤﺮاء، ﻧﻜﻬﺔ اﻟﺨﺮدل ﺑﺎﻟﻌﺴﻞ",
        img_path: "/wcs-menu-designs-aspire/Salads WEB 2/01 WCS - Salads - Quinoa-1-WEB.jpg",
    },

    {
        name: "CAESAR SALAD ",
        description: "ROMAINE LETTUCE, CROUTONS, PARMESAN, GRILLED CHICKEN",
        price_en: "35",
        name_ar: "ﺳﻠﻄﺔ اﻟﺴﻴﺰار ",
        description_ar: "ﺧﺲ روﻣﺎﻧﻲ، ﺧﺒﺰ ﻣﺤﻤﺺ، ﺟﺒﻦ ﺑﺎرﻣﻴﺰان، دﺟﺎج ﻣﺸﻮي",
        img_path: "/wcs-menu-designs-aspire/Salads WEB 2/.jpg",
    },
]

export default salads;