const dessert = [
    {
        name: "VANILLA FLAN ",
        description: "FRESHLY BAKED VANILLA CUSTARD, CARAMEL  ",
        price_en: "35",
        name_ar: "  ﻓﺎﻧﻴﻼ ﻓﻼن ",
        description_ar: " ﻛﺎﺳﺘﺮد ﻃﺎزج ﺑﻨﻜﻬﺔ اﻟﻔﺎﻧﻴﻠﻴﺎ ، ﻛﺮاﻣﻴﻞ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/01 WCS - Dessert - Flan-1-WEB.jpg",
    },

    {
        name: "CANNELÉ  ",
        description: "  ",
        price_en: "15",
        name_ar: "  ﻛﺎﻧﻴﻠﻴﺔ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/.jpg",
    },

    {
        name: "CROISSANT COOKIE, MILK CHOCOLATE ",
        description: "  ",
        price_en: "17",
        name_ar: "  ﻛﻮﻛﻴﺰ اﻟﻜﺮواﺳﺎن ﺷﻮﻛﻮﻻﺗﺔ ﺑﺎﻟﺤﻠﻴﺐ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/03 WCS - Dessert - Croissant Cookie Milk Chocolate-WEB.jpg",
    },

    {
        name: "MILK CHOCOLATE & SEA SALT COOKIE  ",
        description: "  ",
        price_en: "17",
        name_ar: "  ﻛﻮﻛﻴﺰ اﻟﺸﻮﻛﻮﻻﺗﺔ ﺑﺎﻟﺤﻠﻴﺐ وﻣﻠﺢ اﻟﺒﺤﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/04 WCS - Dessert - Milk Chocolate Sea Salt Cookie-1-WEB.jpg",
    },

    {
        name: "BANANA BREAD, PEANUT BUTTER ",
        description: "  ",
        price_en: "27",
        name_ar: "  ﺧﺒﺰ اﻟﻤﻮز ﻣﻊ زﺑﺪة اﻟﻔﻮل اﻟﺴﻮداﻧﻲ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/05 WCS - Dessert - Banana Bread-1-WEB.jpg",
    },

    {
        name: "SAFFRON MAHLAB BOLLER ",
        description: "  ",
        price_en: "13",
        name_ar: "  ﻣﺤﻠﺐ زﻋﻔﺮان ﺑﻮﻟﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/06 WCS - Dessert - Saffron Mahlab Roller-1-WEB.jpg",
    },

    {
        name: "KARAK BOLLER ",
        description: "  ",
        price_en: "13",
        name_ar: "  ﻛﺮك ﺑﻮﻟﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/07 WCS - Dessert - Karak Mahlab Roller-1-WEB.jpg",
    },

    {
        name: "DATE CANDY FINGER BUN ",
        description: "  ",
        price_en: "11",
        name_ar: "  ﺧﺒﺰ اﻟﺘﻤﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/08 WCS - Dessert - Date Candy Finger Bun-1-WEB.jpg",
    },

    {
        name: "CHIFFON CREAM CHEESE ",
        description: "  ",
        price_en: "23",
        name_ar: "  ﺷﻴﻔﻮن ﻛﺮﻳﻢ ﺗﺸﻴﺰ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/09 WCS - Dessert - Chiffon Cream Cheese-1-WEB.jpg",
    },

    {
        name: "HONEY CAKE, TOASTED ALMOND ",
        description: "  ",
        price_en: "19",
        name_ar: "  ﻛﻌﻜﺔ اﻟﻌﺴﻞ، ﻓﺴﺘﻖ ﻣُﻘﺸّﺮ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/10 WCS - Dessert - Honey Cake Toasted Almond-1-WEB.jpg",
    },

    {
        name: "ZUCCHINI WALNUT CAKE, SALTED MAPLE BUTTER  ",
        description: "  ",
        price_en: "27",
        name_ar: "  ﻛﻌﻜﺔ اﻟﻜﻮﺳﺎ ﺑﺎﻟﺠﻮز وزﺑﺪة",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/11 WCS - Dessert - Zucchini Walnut Cake Salted Maple-1-WEB.jpg",
    },

    {
        name: "PISTACHIO SEMOLINA CAKE, WHITE CHOCOLATE HALAWA GANACHE  ",
        description: "  ",
        price_en: "27",
        name_ar: "  ﺑﺴﺘﺎﺷﻴﻮ ﺳﻤﻮﻟﻴﻨﺎ ﻛﻴﻚ ﻏﺎﻧﺎش اﻟﺤﻼوة ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/Dessert WEB 2/12 WCS - Dessert - Pistachio Semolina Cake Butter-WEB.jpg",
    },

]

export default dessert;