const beverages = [
    {
        name: "LAVENDER LEMONADE ",
        description: "  ",
        price_en: "24",
        name_ar: "   ﻻﻓﻨﺪر ﻟﻴﻤﻮﻧﻴﺪ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/beverages/Beverages-01- lavender_lemonade.jpg",
    },

    {
        name: "SAKURA LEMONADE ",
        description: "  ",
        price_en: "21",
        name_ar: "   ﺳﺎﻛﻮرا ﻟﻴﻤﻮﻧﻴﺪ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/beverages/Beverages-03- sakura_lemonade.jpg",
    },

    {
        name: "FRESH ORANGE JUICE ",
        description: "  ",
        price_en: "21",
        name_ar: "   ﻋﺼﻴﺮ ﺑﺮﺗﻘﺎل",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/beverages/Beverages-02- fresh_orange_juice.jpg",
    },

    {
        name: "STILL WATER ",
        description: "  ",
        price_en: "10",
        name_ar: " ﻣﺎء ﻣﻌﺪﻧﻲ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/beverages/Beverages-04- still_water.jpg",
    },

    {
        name: "SPARKLING WATER ",
        description: "  ",
        price_en: "10",
        name_ar: " ﻣﺎء ﻏﺎزي",
        description_ar: " ",
        img_path: "/wcs-menu-designs-westbay/beverages/Beverages-05- sparkling_water.jpg",
    },
]

export default beverages;