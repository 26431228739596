import styled from "styled-components";
import { ErgonMenuPageStyled } from "../styles/PageStyled";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/Typography";
import Typography from "@mui/material/Typography";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import {useState} from "react";
import {PastriesAndCakesEn, PastriesAndCakesAr, DessertsEn, DessertsAr, PitaMeltsEn, PitaMeltsAr, SandwichesEn, SandwichesAr, PanCakesEn, PanCakesAr, ColdHotMezesEn, ColdHotMezesAr, SidesEn, SidesAr, CoffeeEn, CoffeeAr, EggsEn, EggsAr, HomeMadePiesEn, HomeMadePiesAr, ToastsEn, ToastsAr, GreekYogurtBowlEn, GreekYogurtBowlAr, PeinirliEn, PeinirliAr, SaladsEn, SaladsAr,  MainCourseEn, MainCourseAr, CheeseCakeEn, CheeseCakeAr } from "../components/MenuGrids/ergonMenu";


function ErgonMenu({}) {

    const [expanded, setExpanded] = useState(false);
    const handleChange = (panel) => (e, isExpanded) => {
        setExpanded(isExpanded ? panel: false);
    }

    const [menuLanguage, setMenuLanguage] = useState("en")

    function handleLanguage(language) {
        setMenuLanguage(language);
    }

    if(menuLanguage == "en") {
        return (
            <ErgonMenuPageStyled>
            <div style = {{border: "2px solid black"}}>
                <ImageContainerStyled>
                    <img src = "/ergon-menu-desgins/ERGON-Logo-RGB-EN-V2.png"/>
                </ImageContainerStyled>
                <Accordion expanded = {expanded === "panel1"} onChange ={handleChange("panel1")} elevation={0}>
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon/>}>
                        <CategoryTestStyled>
                        <Typography>
                            <CategoryTextStyled>
                                Pastries & Cakes
                            </CategoryTextStyled>
                        </Typography>
                        </CategoryTestStyled>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PastriesAndCakesEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel2"} onChange ={handleChange("panel2")} elevation={0}>
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                           <CategoryTextStyled>
                                Desserts
                           </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <DessertsEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel3"} onChange ={handleChange("panel3")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Pita Melts
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PitaMeltsEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel4"} onChange ={handleChange("panel4")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Sandwiches
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <SandwichesEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel5"} onChange ={handleChange("panel5")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Homemade Pies
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <HomeMadePiesEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel6"} onChange ={handleChange("panel6")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Toasts
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <ToastsEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel7"} onChange ={handleChange("panel7")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Pancakes
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PanCakesEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel8"} onChange ={handleChange("panel8")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Cold & Hot Mezes
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <ColdHotMezesEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel9"} onChange ={handleChange("panel9")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Greek Yogurt Bowl
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <GreekYogurtBowlEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel10"} onChange ={handleChange("panel10")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Eggs
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <EggsEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel11"} onChange ={handleChange("panel11")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Sides
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <SidesEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel12"} onChange ={handleChange("panel12")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Peinirli
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PeinirliEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel13"} onChange ={handleChange("panel13")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Salads
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <SaladsEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel14"} onChange ={handleChange("panel14")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Main Course
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <MainCourseEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    {/* <Accordion expanded = {expanded === "panel15"} onChange ={handleChange("panel15")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                CheeseCake
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <CheeseCakeEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion> */}
                    <Accordion expanded = {expanded === "panel16"} onChange ={handleChange("panel16")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyled>
                                Coffee & Juices
                            </CategoryTextStyled>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <CoffeeEn/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <EnArSwitchStyled>
                        <div style = {{marginTop: "2px"}} onClick = {() => handleLanguage("en")}>
                            English
                        </div>
                        <div> | </div>
                        <div style = {{fontFamily: "Gotham Greek Book", marginTop: "2px"}} onClick = {() => handleLanguage("ar")}>عربي</div>
                    </EnArSwitchStyled>
                    <p style = {{fontSize: "11px", textAlign: "center", marginTop: "45px", marginBottom: "16px", padding: "0px 20px"}}>
                        We cook exclusively with <strong>free-range</strong> eggs, <strong>organic</strong> milk and <strong>artisanal</strong> cheese. Our organic <strong>sourdough</strong> bread & pastries are <strong>freshly</strong> baked daily in Doha.
                    </p>
            </div>
            <p style = {{fontSize: "9px", textAlign: "center", padding: "0px 20px"}}>If you have a food allegy or a special dietary requirement please inform a member of our hospitality team.</p>
            <CopyRightStyled>
                <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                    <img src = "/Developed-By-LSD.svg"/>
                </a>
            </CopyRightStyled>
        </ErgonMenuPageStyled>

    )
    }

    if(menuLanguage == "ar") {
        return (
            <ErgonMenuPageStyled>
            <div style = {{border: "2px solid black"}}>
                <ImageContainerStyled style = {{marginTop: "30px", marginBottom: "30px"}}>
                    <img src = "/ergon-menu-desgins/Ergon Logo- Ar.png"/>
                </ImageContainerStyled>
                <Accordion expanded = {expanded === "panel1"} onChange ={handleChange("panel1")} elevation={0}>
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon/>}>
                        <CategoryTestStyled>
                        <Typography>
                            <CategoryTextStyledAr>
                            الكيك والمعجنات
                            </CategoryTextStyledAr>
                        </Typography>
                        </CategoryTestStyled>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PastriesAndCakesAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel2"} onChange ={handleChange("panel2")} elevation={0}>
                    <AccordionSummary expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                           <CategoryTextStyledAr>
                           حلويات
                           </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <DessertsAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel3"} onChange ={handleChange("panel3")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            فطائر البيتا ميلتس
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PitaMeltsAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel4"} onChange ={handleChange("panel4")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            السندويشات
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <SandwichesAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel5"} onChange ={handleChange("panel5")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            فطائر منزلية
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <HomeMadePiesAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel6"} onChange ={handleChange("panel6")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            التوست
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <ToastsAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel7"} onChange ={handleChange("panel7")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            بانكيك
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PanCakesAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel8"} onChange ={handleChange("panel8")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            مقبلات ساخنة وباردة
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <ColdHotMezesAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel9"} onChange ={handleChange("panel9")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            صحن الزبادي اليوناني
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <GreekYogurtBowlAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel10"} onChange ={handleChange("panel10")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            البيض
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <EggsAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel11"} onChange ={handleChange("panel11")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            طلبات جانبية
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <SidesAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel12"} onChange ={handleChange("panel12")} elevation={0}>
                    <AccordionSummary  expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                             معجنات البينيرلي
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <PeinirliAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel13"} onChange ={handleChange("panel13")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            سلطة
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <SaladsAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel14"} onChange ={handleChange("panel14")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            طبق رئيسي
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <MainCourseAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails>
                    </Accordion>
                    <Accordion expanded = {expanded === "panel15"} onChange ={handleChange("panel15")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            شيزكيك
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <CheeseCakeAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails> 
                    </Accordion>
                    <Accordion expanded = {expanded === "panel16"} onChange ={handleChange("panel16")} elevation={0}>
                    <AccordionSummary className = "category-box" expandIcon = {<ArrowDropDownIcon/>}>
                        <Typography>
                            <CategoryTextStyledAr>
                            القهوة والعصائر
                            </CategoryTextStyledAr>
                        </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            <CategoryContainerStyled>
                                <CoffeeAr/>
                            </CategoryContainerStyled>
                        </Typography>
                    </AccordionDetails> 
                    </Accordion>
                    <EnArSwitchStyled>
                        <div style = {{marginTop: "2px"}} onClick = {() => handleLanguage("en")}>
                           <span>English</span> 
                        </div>
                        <div> | </div>
                        <div style = {{fontFamily: "Gotham Greek Book", marginTop: "2px"}} onClick = {() => handleLanguage("ar")}>عربي</div>
                    </EnArSwitchStyled>
                    <p style = {{fontSize: "11px", textAlign: "center", marginTop: "45px", marginBottom: "16px", padding: "0px 20px", fontFamily: "Gotham Greek Book"}}>
                       <span>نستخدم حصرياَ بيض <strong>بلدي </strong>حليب عضوي، جبنة <strong>مصنوعة يدوياَ</strong> <br></br><span>إن خبز</span><strong>الساوردو </strong><span>العضوي و جميع معجناتنا مخبوزة </span><strong>يوميًا</strong><span>في الدوحة</span></span>
                    </p>
            </div>
            <p style = {{fontSize: "9px", fontFamily: "Gotham Greek Book", textAlign: "center", padding: "0px 20px"}}>الرجاء اخبار فريق الضيافة إذا كان لديكم أي حساسية أو متطلبات تغذية خاصة</p>
            <CopyRightStyled>
                <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                    <img src = "/Developed-By-LSD.svg"/>
                </a>
            </CopyRightStyled>
        </ErgonMenuPageStyled>
        )
    }
    
}


const CategoryContainerStyled = styled.div`
    position: relative;
    margin-top: 28px;
    padding-left: 21px;
    padding-right: 21px;
    
`

const ImageContainerStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 20px;


   img {
    max-width: 120px;
   }
`

//this is the category to style the accordion
const CategoryTestStyled = styled.div`
    position: absolute;
    width:100%;
    margin-left: 2px;
    inset:0;
    display: flex;
    justify-content: center;
    align-items: center;

`

const CategoryTextStyled = styled.div`
    position: absolute;
    inset:0;
    width: 100%;
    height: 50%;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
    font-family: "Gotham Greek Book";
    font-weight: bolder;
`

const CategoryTextStyledAr = styled(CategoryTestStyled)`
    font-family: "GE SS Text";
    font-weight: bold;
`

const EnArSwitchStyled = styled.div`
    font-family: "GE SS Text";
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
`

const CopyRightStyled = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    width: 100%;

    img {
        width:50%;
    }
`

export default ErgonMenu;