const sandwiches = [
    {
        name: "TUNA MELT ",
        description: "UNA, TONNATO SAUCE, CHEDDAR CHEESE, RELISH, DILL PICKLE  ",
        price_en: "31",
        name_ar: " ﺗﻮﻧﺎ ﻣﻴﻠﺖ",
        description_ar: " ﺻﻠﺼﺔ اﻟﺘﻮﻧﺎﺗﻮ، ﺟﺒﻨﺔ اﻟﺸﻴﺪر، رﻳﻠﻴﺶ، ﻣﺨﻠﻞ اﻟﺸﺒﺖ",
        img_path: "/wcs-menu-designs-westbay/All Day Sandwiches WEB 2/01 WCS - All Day Sandwiches - Tuna Melt-WEB.jpg",
    },

    {
        name: "CHICKEN BELL PEPPER ",
        description: "ROASTED CHICKEN, MARINATED BELL PEPPER, GOUDA CHEESE, BABY ROCKET  ",
        price_en: "31",
        name_ar: " دﺟﺎج ﺑﺎﻟﻔﻠﻔﻞ اﻟﺤﻠﻮ ",
        description_ar: " دﺟﺎج ﻣﺸﻮي، ﻓﻠﻔﻞ ﺣﻠﻮ ﻣﺘﺒﻞ، ﺟﺒﻨﺔ ﺟﻮدا، ﺟﺮﺟﻴﺮ ﺻﻐﻴﺮ",
        img_path: "/wcs-menu-designs-westbay/All Day Sandwiches WEB 2/06 WCS - All Day Sandwiches - Chicken Bell Pepper-2-WEB-edit.jpg",
    },

    {
        name: "CROQUE MONSIEUR ",
        description: "SMOKED TURKEY, BEEF SALAMI, GOUDA CHEESE, COMTE CHEESE, BECHAMEL  ",
        price_en: "31",
        name_ar: " ﻛﺮوك ﻣﺴﻴﻮ ",
        description_ar: " دﻳﻚ روﻣﻲ ﻣﺪﺧﻦ، ﺳﻼﻣﻲ ﻟﺤﻢ ﺑﻘﺮي، ﺟﺒﻦ ﺟﻮدة، ﺟﺒﻦ ﻛﻮﻧﺘﻲ، ﺻﻠﺼﺔ اﻟﺒﺸﺎﻣﻴﻞ",
        img_path: "/wcs-menu-designs-westbay/All Day Sandwiches WEB 2/03 WCS - All Day Sandwiches - Croque Monsieur-WEB.jpg",
    },

    {
        name: "EGG MAYO ",
        description: "BOILED EGG, KEWPIE MAYONNAISE, SALT, PEPPER  ",
        price_en: "17",
        name_ar: " ﺑﻴﺾ ﺑﺎﻟﻤﺎﻳﻮﻧﻴﺰ ",
        description_ar: " ﺑﻴﻀﺔ ﻣﺴﻠﻮﻗﺔ، ﻣﺎﻳﻮﻧﻴﺰ ﻛﻴﻮﺑﻲ، ﻣﻠﺢ، ﻓﻠﻔﻞ",
        img_path: "/wcs-menu-designs-westbay/All Day Sandwiches WEB 2/04 WCS - All Day Sandwiches - Egg Mayo-1-WEB.jpg",
    },

    {
        name: "SALMON AVOCADO ",
        description: "SMOKED SALMON, AVOCADO, CREAM CHEESE  ",
        price_en: "35",
        name_ar: " ﺳﻠﻤﻮن ﺑﺎﻷﻓﻮﻛﺎدو ",
        description_ar: " ﺳﻤﻚ اﻟﺴﻠﻤﻮن اﻟﻤﺪﺧﻦ، اﻷﻓﻮﻛﺎدو، ﺟﺒﻨﺔ",
        img_path: "/wcs-menu-designs-westbay/All Day Sandwiches WEB 2/WCS - All Day Sandwiches - Salmon avocado-WEB.jpg",
    },

    {
        name: "ESPELETTE CHICKEN ",
        description: "SMOKED CHICKEN, ESPELETTE PEPPER, KEWPIE MAYONNAISE  ",
        price_en: "29",
        name_ar: " دﺟﺎج إﺳﺒﻴﻠﻴﺔ ",
        description_ar: " دﺟﺎج ﻣﺪﺧﻦ، ﻓﻠﻔﻞ اﺳﺒﻴﻠﻴﺖ، ﻣﺎﻳﻮﻧﻴﺰ ﻳﺎﺑﺎﻧﻲ",
        img_path: "/wcs-menu-designs-westbay/All Day Sandwiches WEB 2/02 WCS - All Day Sandwiches - Chicken Bell Pepper-2-WEBjpg.jpg",
        
    },

    {
        name: "HALLOUMI ",
        description: "GRILLED HALLOUMI, CONFIT CHERRY TOMATO, CREAM CHEESE, MIXED GREENS  ",
        price_en: "29",
        name_ar: " ﺣﻠﻮﻣﻲ ",
        description_ar: " ﺟﺒﻨﺔ اﻟﺤﻠﻮم اﻟﻤﺸﻮﻳﺔ، ﻃﻤﺎﻃﻢ ﻛﺮزﻳﺔ، ﺟﺒﻨﺔ، ﺧﻀﺮاوات ﻣﺸﻜﻠﺔ",
        img_path: "/wcs-menu-designs-westbay/All Day Sandwiches WEB 2/07 WCS - All Day Sandwiches - Halloumi -WEB.jpg",
    },
]

export default sandwiches;