


function OrganizeDashboard() {
    return (
        <>
        in this page we will organize the menu with drag and drop mechanism
        </>
    )
}

export default OrganizeDashboard;
