import styled from "styled-components";


function AllRestaurantsLogin({}) {
    return (
   <>login goes here</>
    )
}





export default AllRestaurantsLogin;