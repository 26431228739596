


function EditDashboard({}) {
    return (
        <>
        this page will be used for editing full sections
        </>
    )
}

export default EditDashboard;