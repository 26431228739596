import {Grid} from "@mui/material"
import { MainCategoryContainerStyled, CategoryContainerStyled, CategoryHeaderStyled, CategoryHeaderArStyled,  CategorySubHeaderStyled, CategorySubHeaderArStyled, CategoryGridStyled, ItemContainerStyled, ItemImageContainer, ItemBoxTwoStyled, ItemDetailsContainer, ItemDetailsStyled, ItemDetailsArStyled,  ItemPriceContainer, ItemPriceContainerAr, ItemNameStyled, ItemNameArStyled } from "./generalStyles";
import beverage from "../../../data/wcsAspireCategories/beverage";
import coffee from "../../../data/wcsAspireCategories/coffee";
import styled from "styled-components";

function BeveragesGrid({triggerLanguage}) {

    const reversedPairsBev = beverage.reduce((acc, _, index, arr)=> {
        if(index %2 === 1) {
            acc.push(arr[index], arr[index-1]);
        }
        return acc
    }, []);

    const reversedPairsCof = coffee.reduce((acc, _, index, arr)=> {
        if(index %2 === 1) {
            acc.push(arr[index], arr[index-1]);
        }
        return acc
    }, []);

    if(triggerLanguage == "en") {
        return (
            <MainCategoryContainerStyled>
                <CategoryContainerStyled>
                        <CategoryHeaderStyled>
                            Beverages
                        </CategoryHeaderStyled>
                        <CategoryGridStyled>
                            <Grid container spacing = {2} rowSpacing = {4}>
                            {
                                beverage.map(({name, description, price_en, img_path}) => {
                                    return (
                                        <Grid item xs = {6} sm = {6}>
                                            <ItemContainerStyled>
                                                <ItemImageContainer>
                                                    <img src = {img_path} />
                                                </ItemImageContainer>
                                                <ItemBoxTwoStyled>
                                                    <ItemDetailsContainer>
                                                        <div>
                                                            <ItemNameStyled>
                                                                {name}
                                                            </ItemNameStyled>
                                                            <ItemDetailsStyled>{description}</ItemDetailsStyled>
                                                        </div>
                                                    </ItemDetailsContainer>
                                                    <ItemPriceContainer>
                                                        {price_en} <span>QR</span>
                                                    </ItemPriceContainer>
                                                </ItemBoxTwoStyled>
                                            </ItemContainerStyled>
                                        </Grid>
                                    )
                                })
                            }
                            </Grid>
                        </CategoryGridStyled>
                    </CategoryContainerStyled>
                    <CategoryContainerStyled>
                    <CategorySubHeaderStyled>
                            COFFEE
                        </CategorySubHeaderStyled>
                        <CategoryGridStyled>
                            <Grid container spacing = {2} rowSpacing = {4}>
                            {
                                coffee.map(({name, description, price_en, img_path}) => {
                                    return (
                                        <Grid item xs = {6} sm = {6}>
                                            <ItemContainerStyled>
                                                <ItemImageContainer>
                                                    <img src = {img_path} />
                                                </ItemImageContainer>
                                                <ItemBoxTwoStyled>
                                                    <ItemDetailsContainer>
                                                        <div>
                                                            <ItemNameStyled>
                                                                {name}
                                                            </ItemNameStyled>
                                                            <ItemDetailsStyled>{description}</ItemDetailsStyled>
                                                        </div>
                                                    </ItemDetailsContainer>
                                                    <ItemPriceContainer>
                                                        {price_en} <span>QR</span>
                                                    </ItemPriceContainer>
                                                </ItemBoxTwoStyled>
                                            </ItemContainerStyled>
                                        </Grid>
                                    )
                                })
                            }
                            </Grid>
                        </CategoryGridStyled>
                    </CategoryContainerStyled>
                    <CategoryContainerStyled>
                        <CategorySubHeaderStyled>
                            TEA
                        </CategorySubHeaderStyled>
                        <TeaStyled>
                        <div>
                            <span>BREAKFAST TEA / 17 QR</span>
                        </div>
                        <div>
                            <span>GREEN TEA CHAMOMILE / 17 QR</span>
                        </div>
                        <div>
                            <span>GREEN TEA / 17 QR</span>
                        </div> 
                        </TeaStyled>
                    </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }

    if(triggerLanguage == "ar") {
        return (
            <MainCategoryContainerStyled>
               <CategoryContainerStyled>
                    <CategoryHeaderArStyled>
                    مشروبات
                    </CategoryHeaderArStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            reversedPairsBev.map(({name_ar, description_ar, price_en, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price_en}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name_ar}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsArStyled>{description_ar}</ItemDetailsArStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                <CategorySubHeaderArStyled>
                قهوة (بارد - ساخن)
                    </CategorySubHeaderArStyled>
                    <CategoryGridStyled>
                        <Grid container spacing = {2} rowSpacing = {4}>
                        {
                            reversedPairsCof.map(({name_ar, description_ar, price_en, img_path}) => {
                                return (
                                    <Grid item xs = {6} sm = {6}>
                                        <ItemContainerStyled>
                                            <ItemImageContainer>
                                                <img src = {img_path} />
                                            </ItemImageContainer>
                                            <ItemBoxTwoStyled>
                                                <ItemPriceContainerAr>
                                                    <div>{price_en}</div>
                                                    <div><span>ر.ق</span></div>   
                                                </ItemPriceContainerAr>
                                                <ItemDetailsContainer style = {{textAlign: "right"}}>
                                                    <div>
                                                        <ItemNameArStyled>
                                                            {name_ar}
                                                        </ItemNameArStyled>
                                                        <ItemDetailsArStyled>{description_ar}</ItemDetailsArStyled>
                                                    </div>
                                                </ItemDetailsContainer>
                                            </ItemBoxTwoStyled>
                                        </ItemContainerStyled>
                                    </Grid>
                                )
                            })
                        }
                        </Grid>
                    </CategoryGridStyled>
                </CategoryContainerStyled>
                <CategoryContainerStyled>
                    <CategoryHeaderArStyled>
                    شاي
                    </CategoryHeaderArStyled>
                    <TeaArStyled>
                        <div>
                            <span>بريكفاست تي / 17 ر.ق</span>
                        </div>
                        <div>
                            <span>شاي أخضر بابونج / 17 ر.ق</span>
                        </div>
                        <div>
                            <span>شاي أخضر / 17 ر.ق</span>
                        </div> 
                    </TeaArStyled>
                </CategoryContainerStyled>
            </MainCategoryContainerStyled>
        )
    }
}

const TeaStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`

const TeaArStyled = styled.div`
    display:flex;
    flex-direction: column;
    gap: 10px;
    text-align: right;

    span {
        font-size: 18px;
        font-weight: bold;
    }
`

export default BeveragesGrid;