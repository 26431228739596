const dessert = [
    {
        name: "CANNELÉ ",
        description: "  ",
        price_en: "15",
        name_ar: "  ﻛﺎﻧﻴﻠﻴﺔ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/.jpg",
    },

    {
        name: "CROISSANT COOKIE, MILK CHOCOLATE  ",
        description: "  ",
        price_en: "17",
        name_ar: "  ﻛﻮﻛﻴﺰ اﻟﻜﺮواﺳﺎن ﺷﻮﻛﻮﻻﺗﺔ ﺑﺎﻟﺤﻠﻴﺐ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/02 WCS - Dessert - Croissant Cookie Milk Chocolate-WEB.jpg",
    },

    {
        name: "MILK CHOCOLATE & SEA SALT COOKIE  ",
        description: "  ",
        price_en: "17",
        name_ar: "  ﻛﻮﻛﻴﺰ اﻟﺸﻮﻛﻮﻻﺗﺔ ﺑﺎﻟﺤﻠﻴﺐ وﻣﻠﺢ اﻟﺒﺤﺮ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/03 WCS - Dessert - Milk Chocolate Sea Salt Cookie-1-WEB.jpg",
    },

    {
        name: "SAFFRON MAHLAB BOLLER  ",
        description: "  ",
        price_en: "13",
        name_ar: "  ﻣﺤﻠﺐ زﻋﻔﺮان ﺑﻮﻟﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/04 WCS - Dessert - Saffron Mahlab Roller-1-WEB.jpg",
    },

    {
        name: "KARAK BOLLER  ",
        description: "  ",
        price_en: "13",
        name_ar: "  ﻛﺮك ﺑﻮﻟﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/05 WCS - Dessert - Karak Mahlab Roller-1-WEB.jpg",
    },

    {
        name: "DATE CANDY FINGER BUN  ",
        description: "  ",
        price_en: "11",
        name_ar: "  ﺧﺒﺰ اﻟﺘﻤﺮ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/06 WCS - Dessert - Date Candy Finger Bun-1-WEB.jpg",
    },

    {
        name: "CHIFFON CREAM CHEESE ",
        description: "  ",
        price_en: "23",
        name_ar: "  ﺷﻴﻔﻮن ﻛﺮﻳﻢ ﺗﺸﻴﺰ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/07 WCS - Dessert - Chiffon Cream Cheese-1-WEB.jpg",
    },

    {
        name: "HONEY CAKE, TOASTED ALMOND ",
        description: "  ",
        price_en: "19",
        name_ar: "  ﻛﻌﻜﺔ اﻟﻌﺴﻞ، ﻓﺴﺘﻖ ﻣُﻘﺸّﺮ ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/08 WCS - Dessert - Honey Cake Toasted Almond-1-WEB.jpg",
    },
    {
        name: "PISTACHIO SEMOLINA CAKE, WHITE CHOCOLATE HALAWA GANACHE ",
        description: "  ",
        price_en: "27",
        name_ar: "  ﺑﺴﺘﺎﺷﻴﻮ ﺳﻤﻮﻟﻴﻨﺎ ﻛﻴﻚ ﻏﺎﻧﺎش اﻟﺤﻼوة ",
        description_ar: " ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/09 WCS - Dessert - Pistachio Semolina Cake Butter-WEB.jpg",
    },

    {
        name: "WHITE SESAME COOKIES ",
        description: "WHITE SESAME, ALMOND FLOUR, UNSALTED BUTTER, MALDON SALT  ",
        price_en: "7",
        name_ar: "  واﻳﺖ ﺳﻴﺴﻤﻲ ﻛﻮﻛﻴﺲ ",
        description_ar: " ﺳﻤﺴﻢ اﺑﻴﺾ، ﻃﺤﻴﻦ اﻟﻠﻮز، زﺑﺪه ﻏﻴﺮ ﻣﻤﻠﺤﺔ، ﻣﻠﺢ ﻣﺎﻟﺪون",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/10 WCS - Dessert - White Sesame cookie-WEB.jpg",
    },

    {
        name: "ENERGY BALL ",
        description: "MEJDOL DATES, CACAO POWDER, ROLLED OATS, NATURAL HONEY, RAW CASHEWS AND ALMOND  ",
        price_en: "14",
        name_ar: "  اﻧﺮﺟﻲ ﺑﺎل",
        description_ar: " ﺗﻤﺮ اﻟﻤﺠﻬﻮل، ﺑﻮدرة اﻟﻜﺎﻛﺎو، ﺷﻮﻓﺎن، ﻋﺴﻞ ﻃﺒﻴﻌﻲ، ﻛﺎﺟﻮ وﻟﻮز ﻧﻴﺊ",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/11 WCS - All Day Sandwiches - Energy Ball-WEB.jpg",
    },

    {
        name: "ORGANIC CHIA SEESA COOKIES ",
        description: "ORGANIC BLACK CHIA, MILLED FLAXSEED, PUMPKIN SEED, BRAZILNUTS, ALMONDS  ",
        price_en: "9",
        name_ar: "  أرﺟﻨﻚ ﺷﻴﺎ ﺳﻴﺪس ﻛﻮﻛﻴﺲ",
        description_ar: " ﺷﻴﺎ ﻋﻀﻮﻳﺔ ﺳﻮداء، ﺑﺬور اﻟﻜﺘﺎن اﻟﻤﻄﺤﻮن، ﺑﺬور اﻟﻴﻘﻄﻴﻦ، ﻣﻜﺴﺮات ﺑﺮازﻳﻠﻴﺔ، ﻟﻮز",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/12 WCS - All Day Sandwiches - Chia Seeds Cookie-WEB.jpg",
    },

    {
        name: "RASPBERRY JAM & MIXED NUTS COOKIES  ",
        description: "DRIED BLUEBERRY, CARAMELIZED ALMONDS, RAW SUGAR, FLEUR DE SEL, RASPBERRY JAM  ",
        price_en: "11",
        name_ar: "  رﺳﺒﺒﺮي ﺟﺎم ﻣﻊ ﻣﻴﻜﺴﺪ ﻧﺘﺲ ﻛﻮﻛﻴﺲ ",
        description_ar: " ﺗﻮت ﻣﺠﻔﻒ، ﻟﻮز ﻣﻜﺮﻣﻞ، ﺳﻜﺮ ﺧﺎم، ﻓﻠﻮر دي ﺳﻴﻞ، ﻣﺮﺑﻰ اﻟﺘﻮت",
        img_path: "/wcs-menu-designs-aspire/DESSERT WEB 2/13 WCS - All Day Sandwiches - Raspberry jam mixed nuts-WEB.jpg",
    },
]

export default dessert;