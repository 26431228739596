import styled from "styled-components";
import {createTheme} from "@mui/material/styles";

//{/* GENERAL STYLING OF THE ITEM CARD */}
const ItemContainerStyled = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
`

const ImgContainerStyled = styled.div`
    position: relative;

    img {
        width: 100%;
    }

`

const ItemEnglishTitleStyled = styled.div`
    position: relative;
    width: 100%;
    margin-bottom: 0px;
    margin-top: 5px;
    display: flex;
`

const ItemEnglishDescriptionStyled = styled.div`
    position: relative;
    width: 100%;
   

    p {
        margin: 0;
     }
`
const PriceContainerStyled = styled.div`
    position: absolute;
    right: 5px;

    span {
        margin-left: 1px;
    }
`

const ItemButtonsContainerStyled = styled.div`
    position: absolute;
    display: flex;
    justify-content: space-around;
    padding: 0px 10px;
    height: 40px;
    right: 0px;
    top: 4%;
    gap: 10px;
    z-index: 1;
    align-items: center;
    transition: 300ms;
`

const ItemButtonStyled = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 40px;
    padding: 10px 10px;
    background-color: white;
    border-radius: 30px;
    scale: 1;
    transition: 300ms;
    color: #585858;
    
  
    &:hover {
        scale: 1.05;
        color:#ffed00;
        background-color:rgb(80,80,80);
    }
`

const PopTextStyled = styled.div`
    position: absolute;
    text-align: center;
    min-width: max-content;
    bottom: 120%;
    margin-left: -60px;
    left: 160%;
    z-index: 1;
    display: block;
    padding: 8px 12px !important;
    font-size: 10px !important;
    font-weight: 500;
    line-height: 16px;
    background: rgba(15.16,20,0.7);
    color: #fff;
    border-color: #252833;
    border-radius: 10px;
    border-width: 1px;
    text-align: center;
`
const PopTextCloseStyled = styled(PopTextStyled)`
    bottom: 5%;
    left: 20%;
`

const ClosePopUpStyled = styled.div`
     position: fixed;
    inset: 0;
    margin:auto;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    width: 50%;
    height: 50vh;
    background-color: white;
    border-radius: 32px;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 2px 10px;
    padding: 10px;
`

const ClosePopButtonStyled = styled.div`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 35px;
    height: 35px;
    top: 0;
    right: 0px;
    margin-top: 10px;
    margin-right: 10px;
    cursor: pointer;
    background-color: rgb(220, 220, 220); //grey colour
    border-radius: 50%;
    transition:  300ms;
    &:hover {
        color: white;
        background-color: rgb(255, 0, 0); // red color
    }
`

//{/* GENERAL STYLING OF THE FORM COMPONENTS */}
const EditFormStyled = styled.div`
    position: relative;
    padding: 20px;

`
const FormContainerStyled = styled.div`
    position: relative;
    margin-top: 50px;
    display: flex;
    flex-direction: column;
    gap: 50px;
   
`

const AddOnContainerStyled = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 10px;
`

const InputContainerWrapperStyled = styled.div`
    position: relative;
    display: flex;
    gap: 50px;

`

const InputContainerStyled = styled.div`
    position: relative;

`

const FileInputStyled = styled.input`
    display: none;
`

const FileInputImageContainerStyled = styled.div`
    position: relative;
    width: 100px;

    img {
        position: absolute;
        inset: 0;
        width: 100%;
    }
`

//{/* GENERAL BUTTON STYLING FOR ADMIN PAGES */}
const GeneralButtonStyled = createTheme({
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#626262", 
                    color: "white",
                    fontWeight: "600",
                    borderRadius: "15px",
                    transition: "300ms",
                    
                    '&:hover': {
                        backgroundColor: "#ffed00", //lsd yellow
                        color: "#626262",
                    }
                }
            }
        }
    }
})


//{{/* ICON STYLES */}}
const tailSpinLoaderConfig = {
    visible: true,
    height: "40",
    width: "40",
    color: "#006580", //blue-ish
    ariaLabel: "tail-spin-loading",
    radius: "1",
}

export {
    //ITEM STYLES
    ItemContainerStyled,
    ImgContainerStyled,
    ItemEnglishTitleStyled,
    ItemEnglishDescriptionStyled,
    PriceContainerStyled,
    ItemButtonsContainerStyled,
    ItemButtonStyled,
    PopTextStyled,
    PopTextCloseStyled,
    ClosePopUpStyled,
    ClosePopButtonStyled,

    //FORMS COMPONENT STYLES
    EditFormStyled,
    FormContainerStyled,
    AddOnContainerStyled,
    InputContainerWrapperStyled,
    InputContainerStyled,
    FileInputStyled,
    FileInputImageContainerStyled,


    //Button styles
    GeneralButtonStyled,

    //icons styles
    tailSpinLoaderConfig,
}