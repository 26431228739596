//{/* ALL FORM FUNCTIONS GO HERE */}
import axios from "axios";


//function that changs the text in the form field based on user input
function handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors) {
    const {name, value} = e.target;

    if(name == 'price') {
        if(/^\d*$/.test(value)) {
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                'price_err_bool': false
            }))
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                'price_err': ''
            }))
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
        else {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                'price_err': 'only numbers are accepted!'
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                'price_err_bool': true
            }))
        }
    }
    else {
        setFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    }

}

//{{/* Functions related to images */}}
//a visible button that handles triggering the file input so we can upload an image
function handleImageInputButton(fileInputRef) {
    fileInputRef.current.click();
}
//hanldes image changes, adds a temporirly image in display, doesn't upload the image to database!
function handleImageChange(e, setFormData, setLoadAnimation, setTempImage) {
    const imageFile = e.target.files[0];
    setFormData((prevFormData) => ({
        ...prevFormData,
        image: imageFile,
    }))
    setLoadAnimation(true);
    const reader = new FileReader(); //object to read the image file stored on the local pc
    reader.onload = function(event) {
        setTempImage(event.target.result)
    }
    reader.readAsDataURL(imageFile);
    setLoadAnimation(false);
}


//{/* FUNCTIONS SPECIFIC TO FORMS WITH ADD ON FIELDS */}
//hanldes the add on section - only specific to menus with add ons
function handleAddOn(setFormData) {
    setFormData((prevFormData) => ({
        ...prevFormData,
        add_ons: [...prevFormData.add_ons, {name_en: "", price: ""}]
    }))
}
//removes the add on field when we click on the button "remove"
function  handleRemoveAddOn(index, setFormData) {
    setFormData((prevFormData) => ({
        ...prevFormData,
        add_ons: prevFormData.add_ons.filter((_,i) => i !== index)
    }))
}
//handles the add on changes as the user types in add on
function handleAddOnChange(index, event, setTriggerFieldError, setFieldsErrors, setFormData, formData){
    const {name, value} = event.target
    if(name == 'price') {
        if(/^\d*$/.test(value)) {
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                'add_on_price_err_bool': false
            }))
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                'add_on_price_err': ''
            }))
            const newAddOns = [...formData.add_ons]
            newAddOns[index][name] = value;
            setFormData((prevFormData) => ({
                ...prevFormData,
                add_ons: newAddOns
            }))
        }
        else {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                'add_on_price_err': 'only numbers are accepted!'
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                'add_on_price_err_bool': true
            }))
        }
    }
    else {
        const newAddOns = [...formData.add_ons]
        newAddOns[index][name] = value;
        setFormData((prevFormData) => ({
            ...prevFormData,
            add_ons: newAddOns
        }))   
    }
}

export {
    handleChange,
    handleImageChange,
    handleImageInputButton,
    handleAddOn,
    handleAddOnChange,
    handleRemoveAddOn
}