import {Box, TextField, Button, MenuItem} from "@mui/material";
import styled from "styled-components";
import { useState, useEffect, useRef } from "react";
import axios from 'axios';
import {useParams, useLocation, useNavigate} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { GeneralButtonStyled, FormContainerStyled, ButtonWrapperStyled, BottomContainerStyled, InputContainerWrapperStyled, InputContainerStyled, FileInput, FileInputImageContainerStyled, AddOnContainerStyled } from "../components/MenuGrids/lbMenuNew/CommonStyled";
import { TailSpin } from "react-loader-spinner";
import { handleFormPopUpMsg } from "./functions";
import { PopUpMessageContainer } from "./components";


//this component is for editing an item that already exists in the data base
function EditItem() {

    const {id} = useParams();
   
    const url = 'https://www.lovelysunnydayapi.com/api/v1/testMenus';
    // const url = 'https://www.lovelysunnydayapi.com/api/v1/testMenus';
    const fileInputRef = useRef(null);
    const navigate  = useNavigate();
    const location = useLocation();
    const from  = location.state?.from?.pathname || "/restaurants/menus/login";
    const [fillSubCategory, setFillSubCategory] = useState([])
    const [loading, setLoading] = useState(false);
    const [popUpMesage, setPopUpMessage] = useState("");
    const [showAlert, setShowAlert] = useState(false);
    const [tempImage, setTempImage] = useState("");


    //the text fields of the form
    const [formData, setFormData] = useState({
        name_en: '',
        name_ar: '',
        price: '',
        category: '',
        sub_category: '',
        description_en: '',
        description_ar: '',
        add_ons: [],
        hide: '',
        image: '',
        old_image: '',
    })

    //the errors of the text fields
    const [fieldsErrors, setFieldsErrors] = useState({
        name_err: '',
        name_ar_err: '',
        price_err: '',
        category_err: '',
        sub_category_err: '',
        add_on_price_err: '',

    })

    //trigger error boolean for field
    const [triggerFieldError, setTriggerFieldError] = useState({
        name_err_bool: false,
        name_ar_err_bool: false,
        price_err_bool: false,
        add_on_price_err_bool: false,
        category_err_bool: false,
        sub_category_err_bool: false,
    })

    const categoryList = [
        {
            value: "BREAKFAST",
            label: "BREAKFAST",
        },
        {
            value: "A LA CARTE",
            label: "A LA CARTE",
        },

        {
            value: "BOUTIQUE",
            label: "BOUTIQUE",
        }
    ]

    const subCategories = {
        "breakfast": ["EGGS", "TOASTS", "PUDDING & YOGURT"],
        "A LA CARTE": ["SOUP", "SALAD", "HOT STARTER", "SANDWICHES", "MAIN COURSE", "PASTA & RISOTTO", "DESSERT", "CAKES", "MOCKTAILS", "SOFT DRINKS", "PREMIUM TEA", "PREMIUM COFFEE"],
        "BOUTIQUE": ["SOURDOUGH", "BAGUETTE", "DANISH", "CROISSANT", "SCONES"]
    }

    function handleInputButtonClick() {
        fileInputRef.current.click();
    } 
    
    function handleChange(e) {
        const {name, value} = e.target;

        if(name == 'price') {
            if(/^\d*$/.test(value)) {
                setTriggerFieldError((prevErrors) => ({
                    ...prevErrors,
                    'price_err_bool': false
                }))
                setFieldsErrors((prevErrors) => ({
                    ...prevErrors,
                    'price_err': ''
                }))
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    [name]: value,
                }));
            }
            else {
                setFieldsErrors((prevErrors) => ({
                    ...prevErrors,
                    'price_err': 'only numbers are accepted!'
                }))
                setTriggerFieldError((prevErrors) => ({
                    ...prevErrors,
                    'price_err_bool': true
                }))
            }
        }
        else {
            setFormData((prevFormData) => ({
                ...prevFormData,
                [name]: value,
            }));
        }
    };

    //appends an object to the add on list when the user clicks on add on button
    function handleAddOn() {
        setFormData((prevFormData) => ({
            ...prevFormData,
            add_ons: [...prevFormData.add_ons, {name_en: '', price: ''}]
        }))
    }

    function handleRemoveAddOn(index) {
        setFormData((prevFormData) => ({
            ...prevFormData,
            add_ons: prevFormData.add_ons.filter((_,i) => i !== index)
        }))
    }

    //handles the change as the user types in the add on and
    //updates the form
    function handleAddOnChange(index, event) {
        const {name, value} = event.target
        if(name == 'price') {
            if(/^\d*$/.test(value)) {
                setTriggerFieldError((prevErrors) => ({
                    ...prevErrors,
                    'add_on_price_err_bool': false
                }))
                setFieldsErrors((prevErrors) => ({
                    ...prevErrors,
                    'add_on_price_err': ''
                }))
                const newAddOns = [...formData.add_ons]
                newAddOns[index][name] = value;
                setFormData((prevFormData) => ({
                    ...prevFormData,
                    add_ons: newAddOns
                }))
            }
            else {
                setFieldsErrors((prevErrors) => ({
                    ...prevErrors,
                    'add_on_price_err': 'only numbers are accepted!'
                }))
                setTriggerFieldError((prevErrors) => ({
                    ...prevErrors,
                    'add_on_price_err_bool': true
                }))
            }
        }
        else {
            const newAddOns = [...formData.add_ons]
            newAddOns[index][name] = value;
            setFormData((prevFormData) => ({
                ...prevFormData,
                add_ons: newAddOns
            }))   
        }
    }

    //when uploading the image, the function will trigger and we wills end the image to the server
    async function onInputChange(e) {

        const imageFile = e.target.files[0];
        setFormData((prevFormData) => ({
            ...prevFormData,
            image: imageFile,
        }))
        console.log(formData.image);
      
        // const imageData = new FormData();
        // imageData.append('file', imageFile);

        setLoading(true);
        const reader = new FileReader();
        reader.onload = function(event) {
            setTempImage(event.target.result)
        }
        reader.readAsDataURL(imageFile);
        setLoading(false);

        // try {
        //     const {data:{image:{src}}} = await axios.post(`https://www.lovelysunnydayapi.com/api/v1/testMenus/upload`, imageData, {
        //         headers: {
        //             'Content-Type': 'multipart/form-data'
        //         },
        //         withCredentials: true,
        //     })
            
        //     setFormData((prevFormData) => ({
        //         ...prevFormData,
        //         'image': src
        //     }))
            
        //     console.log(formData)
        // } catch (error) {
        //     console.log(error)
        // }
    }

    

    function checkForError() {
        var errorExists = 0;
        if(formData.name_en == '') {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                "name_err": 'Please provide a name for the item!'
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "name_err_bool": true
            }))
            errorExists = 1;
        }
        if(formData.name_ar == '') {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                "name_ar_err": 'Please provide a name for the item!'
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "name_ar_err_bool": true
            }))
            errorExists = 1;
        }
        if(formData.price == null) {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                "price_err": "Please provide a price for the item!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "price_err_bool": true,
            }))
            errorExists = 1;
        }
        if(formData.category == '') {
            setFieldsErrors((prevErrors) => ({
                ...prevErrors,
                "category_err": "Please provide a category for the item!"
            }))
            setTriggerFieldError((prevErrors) => ({
                ...prevErrors,
                "category_err_bool": true,
            }))
            errorExists = 1;
        }
        if(errorExists == 1){
            return true
        }
        else {
            return false;
        }
    }

    async function handleSubmit(e) {
        e.preventDefault();
        const errorExists = checkForError();
        if(errorExists) {
            console.log("error exists!") //inset a pop up that says fill the red fields
        }
        else {
            try {
                const itemData = new FormData();
                const imageFile = formData.image;
                console.log("the image file is: ", imageFile);
                itemData.append('file', imageFile);

                for (const key in formData) {
                    if(formData.hasOwnProperty(key) && key !== 'image') {
                        if(Array.isArray(formData[key])) {
                            itemData.append(key, JSON.stringify(formData[key]))
                        } else {
                            itemData.append(key, formData[key]);
                        }
                    }
                }
                const {data:{msg}} = await axios.patch(`http://localhost:5000/api/v1/testMenus/${id}`, itemData, {
                    headers: {
                    },
                    withCredentials: true,
                })
                setPopUpMessage(msg);
                handleFormPopUpMsg(setShowAlert)
            }
               
            catch (error) {
                setPopUpMessage(error);
                handleFormPopUpMsg(setShowAlert)
            }
        }
    }

     //function to handle logging out
     async function handleLogout(e) {
        e.preventDefault();
        try {
            await fetch("https://www.lovelysunnydayapi.com/api/v1/auth/logout", {
                credentials: "include",
            })
            localStorage.removeItem("auth")
            navigate(from, {replace: true})
        } catch (error) {
            
        }
    }

    const characterCount = `${formData.description_en.length}/200`
    var characterCountAr = `${formData.description_ar.length}/200`

    useEffect(() => {
        const getItem = async() => {
            const link = `${url}/${id}`;
            const {data: {item: {_id, name_en, name_ar, description_en, description_ar, price, category, sub_category, add_ons, hide, image}}} = await axios.get(link, {withCredentials: true},);
            setFormData({
                name_en,
                name_ar,
                price,
                category,
                sub_category,
                description_en,
                description_ar,
                add_ons,
                hide,
                image,
                old_image: image,
            })
            setTempImage(image);
        }
       
        getItem()
     
        
    }, [])

    //set subcategory based on whats chosen in the category
    useEffect(() => {
        if(formData.category) {
            switch(formData.category) {
                case "BREAKFAST":
                    setFillSubCategory(subCategories.breakfast)
                break;

                case "A LA CARTE":
                    setFillSubCategory(subCategories["A LA CARTE"])
                break;

                case "BOUTIQUE":
                    setFillSubCategory(subCategories.BOUTIQUE)
                break;
            }
        }
        else {
            setFillSubCategory([]);
        }
    }, [formData.category])

   
    return (
        <>
            {showAlert && <PopUpMessageContainer popUpMessage={popUpMesage}/>}
            <FormContainerStyled onSubmit = {handleSubmit}>
                <ButtonWrapperStyled>
                    <ThemeProvider theme = {GeneralButtonStyled}>
                        <Button
                            onClick = {handleLogout}
                            variant = "contained"
                        >
                            LOG OUT
                        </Button>
                    </ThemeProvider>
                </ButtonWrapperStyled>
                <Box
                    component = "form"
                    sx = {{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 7,
                        width: "50%",

                    }}
                    noValidate
                    autoCapitalize="off"
                >
                    <TextField
                        required
                        id = "standard-basic"
                        label = "Name"
                        name = "name_en"
                        value = {formData.name_en}
                        onChange = {handleChange}
                        helperText = {fieldsErrors.name_err}
                        error = {triggerFieldError.name_err_bool}
                    >
                    </TextField>
                    <TextField
                        required
                        id = "standard-basic"
                        label = "الآسم"
                        name = "name_ar"
                        value = {formData.name_ar}
                        onChange = {handleChange}
                        helperText = {fieldsErrors.name_ar_err}
                        error = {triggerFieldError.name_ar_err_bool}
                    >
                    </TextField>
                    <TextField
                        required
                        id = "standard-basic"
                        label = "Price"
                        name = "price"
                        value = {formData.price}
                        onChange = {handleChange}
                        helperText = {fieldsErrors.price_err}
                        error = {triggerFieldError.price_err_bool}
                        inputProps = {{pattern: '[0-9]*'}}
                    >
                    </TextField>
                    <TextField
                        required
                        id = "standard-select"
                        label = "Category"
                        name = "category"
                        select
                        value = {formData.category}
                        onChange = {handleChange}
                        helperText = {fieldsErrors.category_err}
                        error = {triggerFieldError.category_err_bool}
                    >
                         <MenuItem value="" disabled>
                        Select a category
                    </MenuItem>
                    {categoryList.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                        {option.label}
                        </MenuItem>
      ))}
                    </TextField>
                    {formData.category && <TextField
                        required
                        id = "standard-select"
                        label = "Sub-Category"
                        name = "sub_category"
                        select
                        value = {formData.sub_category}
                        onChange = {handleChange}
                        helperText = {fieldsErrors.sub_category_err}
                        error = {triggerFieldError.sub_category_err_bool}
                    >
                         <MenuItem value="" disabled>
                        Select a sub-category
                    </MenuItem>
                    {fillSubCategory.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                        </MenuItem>
      ))}
                    </TextField>}
                    <TextField
                        id = "outlined-multiline-flexible"
                        label = "Description"
                        name = "description_en"
                        multiline
                        rows = {4}
                        value = {formData.description_en}
                        onChange = {handleChange}
                        inputProps = {{maxLength: 200}}
                        sx = {{width:"100%"}}
                        InputProps = {{
                            endAdornment: (
                                <Box
                                    sx = {{
                                        textAlign: 'right',
                                        color: 'gray',
                                        fontSize: '10px',
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        padding: '4px',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {characterCount}
                                </Box>
                            )
                        }}
                    >
                    </TextField>
                    <TextField
                        id = "outlined-multiline-flexible"
                        label = "وصف"
                        name = "description_ar"
                        multiline
                        rows = {4}
                        value = {formData.description_ar}
                        onChange = {handleChange}
                        inputProps = {{maxLength: 200}}
                        sx = {{width:"100%"}}
                        InputProps = {{
                            endAdornment: (
                                <Box
                                    sx = {{
                                        textAlign: 'right',
                                        color: 'gray',
                                        fontSize: '10px',
                                        position: 'absolute',
                                        bottom: 0,
                                        right: 0,
                                        padding: '4px',
                                        backgroundColor: 'white',
                                    }}
                                >
                                    {characterCountAr}
                                </Box>
                            )
                        }}
                    >
                    </TextField>
                    <ThemeProvider theme = {GeneralButtonStyled}>
                        <Button
                                    variant="contained"
                                    onClick={handleAddOn}
                                >
                                    Add-On
                        </Button>
                    </ThemeProvider>
                    {formData.add_ons.map((addOn, index) => (
                        <AddOnContainerStyled key = {index}>
                            <TextField
                                label="Name"
                                name="name_en"
                                value={addOn.name_en}
                                onChange={(event) => handleAddOnChange(index, event)}
                                
                            >
                            </TextField>
                            <TextField
                                label="الآسم"
                                name="name_ar"
                                value={addOn.name_ar}
                                onChange={(event) => handleAddOnChange(index, event)}
                                
                            >
                            </TextField>
                            <TextField
                                label="Price"
                                name="price"
                                value={addOn.price}
                                helperText = {fieldsErrors.add_on_price_err}
                                error = {triggerFieldError.add_on_price_err_bool}
                                onChange={(event) => handleAddOnChange(index, event)}
                                
                            >
                            </TextField>
                            <ThemeProvider theme = {GeneralButtonStyled}>
                                    <Button
                                variant="contained"
                                onClick={() => handleRemoveAddOn(index)}
                                style = {{padding: "20px"}}
                            >
                                remove
                            </Button>
                            </ThemeProvider>
                        </AddOnContainerStyled>
                    ))}
                    <InputContainerWrapperStyled>
                        <InputContainerStyled>
                            <FileInput
                                type= "file"
                                accept="image/*"
                                ref={fileInputRef}
                                onChange={onInputChange}
                            />
                            <ThemeProvider theme = {GeneralButtonStyled}>
                                <Button
                                    variant = "contained"
                                    onClick = {handleInputButtonClick}
                                >
                                    edit image
                                </Button>
                            </ThemeProvider>
                        </InputContainerStyled>
                        <FileInputImageContainerStyled>
                            {!loading ? <img src = {tempImage} />:
                                <TailSpin
                                visible={true}
                                height="40"
                                width="40"
                                color="#006580"
                                ariaLabel="tail-spin-loading"
                                radius="1"
                            >
                            </TailSpin>
                            }
                        </FileInputImageContainerStyled>
                    </InputContainerWrapperStyled>
                    <BottomContainerStyled>
                        <ThemeProvider theme = {GeneralButtonStyled}>
                            <Button type = "submit" variant = "contained" color = "primary" style = {{width: "50%"}}>
                                Submit
                            </Button>
                        </ThemeProvider>
                    </BottomContainerStyled>
                </Box>
            </FormContainerStyled>
        </>
    )
}



export default EditItem;
