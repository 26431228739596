import styled from "styled-components";
import {useEffect, useState, useRef} from "react";
import { CopyRightStyled } from "../../components/MenuGrids/wcsMenuAspire/generalStyles";
import {AddOnsGrid, BeveragesGrid, BreakfastGrid, DessertGrid, SaladsGrid, SandwichesGrid} from "../../components/MenuGrids/wcsMenuAspire";
import { useNavigate } from "react-router-dom";



function WcsMenuAspireEn({}) {
    const [triggerLanguage, setTriggerLanguage] = useState('en');
    const sliderContainer = useRef(null);
    const whiteSectionsRef = useRef([]);
    const blueDotRef = useRef([]);
    const whiteCatRef = useRef([]);
    let categoriesIndices = ['0', '1', '2', '3', '4', '5'];
    const navigate = useNavigate();

    function handleTriggerMenu() {
        navigate("/restaurants/menus/white-coffee-spot-aspire-ar");
    }

    function navClickHandler(id) {
        activeCategoryHandler(id);
    }

    //activates/deactivates the categories that we click on or we are currently on
    function activeCategoryHandler(sectionId) {
        var index = sectionId-1;

        blueDotRef.current[index].style.display = "flex"
        categoriesIndices.map((item) => {
            if(item != index) {
                blueDotRef.current[item].style.display = "none"
            }
        })
    }

    //this function tracks the user position as he scrolls down the page
    function windowScrollTracker(currentScroll) {

        var sectionFromTop;
        var sectionId;

        //gets the id of the cateogry we are on as we scroll
        whiteSectionsRef.current.forEach((section) => {
            sectionFromTop = section.getBoundingClientRect().top;
          
            if (sectionFromTop <= 2) {
                sectionId = section.getAttribute("id");
            }
        }) 

        console.log("the current section id is: ", sectionId)

        navBarTracker(sectionId)
    }

    async function navBarTracker(sectionId) {

        switch(sectionId) {
            //activates the to breakfast cateogry
            case '1':
                sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates sandwiches cateogory
            case '2':
                sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates salads category
            case '3':
                sliderContainer.current.scrollLeft = 0;
                await activeCategoryHandler(sectionId)
            break;

            //activates the dessert category
            case '4':
                sliderContainer.current.scrollLeft = 200;
                await activeCategoryHandler(sectionId)
            break;

            //activates the beverages category
            case '5':
                sliderContainer.current.scrollLeft = 200;
                await activeCategoryHandler(sectionId)
            break;

            //activates the add ons category
            case '6':
                sliderContainer.current.scrollLeft = 200;
                await activeCategoryHandler(sectionId)
            break;
        }
        return "resolved";
    }

    useEffect(() => {
        function handleScroll(e) {
            var currentScroll = window.pageYOffset;
            windowScrollTracker(currentScroll);
            console.log(triggerLanguage)
        }

        var timer = null;

            window.addEventListener('scroll', function() {
                if(timer !== null) {
                    clearTimeout(timer)
                }
                timer = setTimeout(function () {
                    handleScroll()
                }, 50)
            })
    }, [])

    return (
        <WhiteCoffeePageStyled>
            <SliderContainerStyled ref= {sliderContainer}>
            <NavTextStyled>
                <a href = "#1" ref = {(el) => whiteCatRef.current[0] = el} onClick = {() => navClickHandler("1")}>
                    <span>breakfast</span>
                    <BlueDotStyled ref = {(el)=> blueDotRef.current[0] = el} className = "animate__animated animate__bounceIn">
                        <img src = "/wcs-menu-designs-aspire/blue-dot.jpg" href = "white coffee spot blue dot logo"/>                                
                </BlueDotStyled> 
                </a>  
            </NavTextStyled>
            <NavTextStyled>
                <BlueDotStyled ref = {(el)=> blueDotRef.current[1] = el} className = "animate__animated animate__bounceIn">
                    <img src = "/wcs-menu-designs-aspire/blue-dot.jpg" href = "white coffee spot blue dot logo"/>                                
                </BlueDotStyled> 
                <a href = "#2" ref = {(el) => whiteCatRef.current[1] = el} onClick = {() => navClickHandler("2")}>
                    <span>sandwiches</span>
                </a>  
            </NavTextStyled>
            <NavTextStyled>
                <a href = "#3" ref = {(el) => whiteCatRef.current[2] = el} onClick = {() => navClickHandler("3")}>
                    <span>salads</span>
                <BlueDotStyled ref = {(el)=> blueDotRef.current[2] = el} className = "animate__animated animate__bounceIn">
                    <img src = "/wcs-menu-designs-aspire/blue-dot.jpg" href = "white coffee spot blue dot logo"/>                                
                </BlueDotStyled> 
                </a>  
            </NavTextStyled>
            <NavTextStyled onClick = {() => navClickHandler("4")}> 
                <a href = "#4" ref = {(el) => whiteCatRef.current[3] = el}>
                    <span>dessert</span>
                    <BlueDotStyled ref = {(el)=> blueDotRef.current[3] = el} className = "animate__animated animate__bounceIn">
                        <img src = "/wcs-menu-designs-aspire/blue-dot.jpg" href = "white coffee spot blue dot logo"/>                                
                    </BlueDotStyled>
                </a>  
            </NavTextStyled>
            <NavTextStyled> 
                <a href = "#5" ref = {(el) => whiteCatRef.current[4] = el} onClick = {() => navClickHandler("5")}>
                    <span>beverages</span>
                    <BlueDotStyled ref = {(el)=> blueDotRef.current[4] = el} className = "animate__animated animate__bounceIn">
                    <img src = "/wcs-menu-designs-aspire/blue-dot.jpg" href = "white coffee spot blue dot logo"/>                                
                    </BlueDotStyled>
                </a>  
            </NavTextStyled>
            <NavTextStyled> 
                <a href = "#6" ref = {(el) => whiteCatRef.current[5] = el} onClick = {() => navClickHandler("6")}>
                    <span>add ons</span>
                    <BlueDotStyled ref = {(el)=> blueDotRef.current[5] = el} className = "animate__animated animate__bounceIn">
                    <img src = "/wcs-menu-designs-aspire/blue-dot.jpg" href = "white coffee spot blue dot logo"/>                                
                    </BlueDotStyled>
                </a>  
            </NavTextStyled>
            </SliderContainerStyled>
            <LanguageTriggerContainerStyled>
                <span onClick = {() => handleTriggerMenu("ar")}>عربي</span>
            </LanguageTriggerContainerStyled>
            <CategoryContainerStyled>
                <section id = "1" ref = {(el) => whiteSectionsRef.current[0] = el}>
                    <BreakfastGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "2" ref = {(el) => whiteSectionsRef.current[1] = el}>
                    <SandwichesGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "3" ref = {(el) => whiteSectionsRef.current[2] = el}>
                    <SaladsGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "4" ref = {(el) => whiteSectionsRef.current[3] = el}>
                    <DessertGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "5" ref = {(el) => whiteSectionsRef.current[4] = el}>
                    <BeveragesGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CategoryContainerStyled>
                <section id = "6" ref = {(el) => whiteSectionsRef.current[5] = el}>
                    <AddOnsGrid triggerLanguage = {triggerLanguage}/>
                </section>
            </CategoryContainerStyled>
            <CopyRightStyled>
                    <a href = "https://www.lovelysunnyday.com/home" target = "_blank" style = {{position: "absolute" , inset: "0",  width: "100%" , display: "flex", justifyContent: "center"}}>
                                <img src = "/Developed-By-LSD.svg"/>
                    </a>
                </CopyRightStyled>
        </WhiteCoffeePageStyled>
    )
}

const WhiteCoffeePageStyled = styled.div`
    position: relative;
    width: 100vw;
`

const SliderContainerStyled = styled.div`
    position: fixed;
    inset: 0;
    padding: 10px 15px;
    display: flex;
    justify-content: space-between;
    background-color: white;
    gap: 20px;
    align-items: center;
    width: 80%;
    height: 50px;
    z-index: 1;
    overflow: scroll;
    scroll-behavior: smooth;
    //flex-direction: ${props => props.triggerLanguage === "ar" ? "row-reverse" : "row"};
`


const NavTextStyled = styled.div`
    position: relative;
    text-transform: uppercase;
    white-space: nowrap;
    font-family:  "Ambition & Ink";
    font-size: 18px;
    

    a {
        color: #3D3935;
        z-index: 2;
    }
`

const BlueDotStyled = styled.div`
    position: absolute;
    inset: 0;
    display: none;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    z-index: -1;
    img {
        position: absolute;
        height: 30px;
        width: 30px;
      
    }
`
const LanguageTriggerContainerStyled = styled.div`
    position: fixed;
    display: flex;
    justify-content: center;
    background-color: white;
    align-items: center;
    z-index: 1;
    inset: 0px;
    left: 80%;
    width: 20%;
    height: 50px;
    font-family: "DIN Next LT Arabic";
    font-size: 12px;

     span {
        margin-top: -9px;
        padding: 5px;
     }
`
const CategoryContainerStyled = styled.div`
    position: relative;
    margin-top: 80px;
`

export default WcsMenuAspireEn;