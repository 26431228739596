import { MenuItem, TextField, Button } from "@mui/material";
import {leBleuItem, leBleuFieldErrors, leBleuTriggerFieldError, leBleuCategoryList, leBleuSubCategories} from "../../data/LeBleu";
import {useEffect, useState} from "react";
import { handleChange, handleAddOn, handleAddOnChange, handleRemoveAddOn, handleImageChange, handleImageInputButton} from "../../functions/formFunctions";
import { GeneralButtonStyled, AddOnContainerStyled,  InputContainerWrapperStyled, InputContainerStyled, FileInputStyled, FileInputImageContainerStyled, tailSpinLoaderConfig } from "../generalStyles";
import {ThemeProvider} from "@mui/material/styles";
import { TailSpin } from "react-loader-spinner";

function LeBleuForm({formData, setFormData, fieldsErrors, setFieldsErrors, triggerFieldError, setTriggerFieldError, setCategoryList, tempImage, setTempImage, loadAnimation, setLoadAnimation, fileInputRef}) {

    const [subCategories, setSubCategories] = useState([]);
    const [fillSubCategory, setFillSubCategory] = useState([]);


    useEffect(() => {
        setFieldsErrors(leBleuFieldErrors)
        setTriggerFieldError(leBleuTriggerFieldError)
        setCategoryList(leBleuCategoryList)
        setSubCategories(leBleuSubCategories)
    }, [])

    //triggers the sub-cateogry drop menu based on the main category chosen
    useEffect(() => {
        switch(formData.category) {
            case "BREAKFAST":
                setFillSubCategory(subCategories.breakfast)
            break;

            case "A LA CARTE":
                setFillSubCategory(subCategories["A LA CARTE"])
            break;

            case "BOUTIQUE":
                setFillSubCategory(subCategories.BOUTIQUE)
            break;
        }
    }, [formData.category])

    return (
        <>
            <TextField
                required
                id = "standard-basic"
                label = "Name"
                name = "name_en"
                value = {formData.name_en}
                onChange = {(e) => handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors)}
                helperText = {fieldsErrors.name_err}
                error = {triggerFieldError.name_err_bool}
            />
            <TextField
                required
                id = "standard-basic"
                label = "الآسم"
                name = "name_ar"
                value = {formData.name_ar}
                onChange = {(e) => handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors)}
                helperText = {fieldsErrors.name_ar_err}
                error = {triggerFieldError.name_ar_err_bool}
            />
            <TextField
                required
                id = "standard-basic"
                label = "Price"
                name = "price"
                value = {formData.price}
                onChange = {(e) => handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors)}
                helperText = {fieldsErrors.price_err}
                error = {triggerFieldError.price_err_bool}
                inputProps = {{pattern: '[0-9]*'}}
            />
           {formData.category && <TextField
                required
                id = "standard-basic"
                label = "Category"
                name = "category"
                select
                value = {formData.category}
                onChange = {(e) => handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors)}
                helperText = {fieldsErrors.category_err}
                error = {triggerFieldError.category_err_bool}
            >
                <MenuItem value = "" disabled>
                    Select a category
                </MenuItem>
                {leBleuCategoryList.map((option) => (
                    <MenuItem key = {option.value} value = {option.value}>
                        {option.label}
                    </MenuItem>
                ))}
            </TextField>}
            {formData.category && <TextField
                required
                id = "standard-select"
                label = "Sub-Category"
                name = "sub_category"
                select
                value = {formData.sub_category}
                onChange = {(e) => handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors)}
                helperText = {fieldsErrors.sub_category_err}
                error = {triggerFieldError.sub_category_err_bool}
            >
                    <MenuItem value="" disabled>
                Select a sub-category
            </MenuItem>
            {fillSubCategory.map((option) => (
                <MenuItem key={option} value={option}>
                {option}
                </MenuItem>
))}
            </TextField>}
            <TextField
                id = "outlined-multiline-flexible"
                label = "Description"
                name = "description_en"
                multiline
                rows = {4}
                value = {formData.description_en}
                onChange = {(e) => handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors)}
                inputProps = {{maxLength: 200}}
            >
            </TextField>
            <TextField
                id = "outlined-multiline-flexible"
                label = "وصف"
                name = "description_ar"
                multiline
                rows = {4}
                value = {formData.description_ar}
                onChange = {(e) => handleChange(e, setFormData, setTriggerFieldError, setFieldsErrors)}
                inputProps = {{maxLength: 200}}
            >
            </TextField>
            {/* ADD ON SECTION IS NOT INCLUDED IN OTHER FORMS */}
            <ThemeProvider theme = {GeneralButtonStyled}>
                <Button
                    variant="contained"
                    onClick={() => handleAddOn(setFormData)}
                >
                    Add-On
                </Button>
            </ThemeProvider>
            {formData.add_ons && formData.add_ons.map((addOn, index) => {
                return (
                        <AddOnContainerStyled key = {index}>
                        <TextField
                            label="Name"
                            name="name_en"
                            value={addOn.name_en}
                            onChange={(event) => handleAddOnChange(index, event, setTriggerFieldError, setFieldsErrors, setFormData, formData)}
                        />
                        <TextField
                            label="Price"
                            name="price"
                            value={addOn.price}
                            helperText = {fieldsErrors.add_on_price_err}
                            error = {triggerFieldError.add_on_price_err_bool}
                            onChange={(event) => handleAddOnChange(index, event, setTriggerFieldError, setFieldsErrors, setFormData, formData)}
                        />
                        <ThemeProvider theme = {GeneralButtonStyled}>
                            <Button
                                variant = "contained"
                                onClick={()=> handleRemoveAddOn(index, setFormData)}
                            >
                                remove
                            </Button>
                        </ThemeProvider>
                    </AddOnContainerStyled>
                )
            })}
            <InputContainerWrapperStyled>
                <InputContainerStyled>
                    <FileInputStyled
                        type="file"
                        accept="image/*"
                        ref = {fileInputRef}
                        onChange={(e) => handleImageChange(e, setFormData, setLoadAnimation, setTempImage)}
                   />
                   <ThemeProvider theme = {GeneralButtonStyled}>
                    <Button
                        variant = "contained"
                        onClick = {() => handleImageInputButton(fileInputRef)}
                    >
                        edit image
                    </Button>
                   </ThemeProvider>
                </InputContainerStyled>
                <FileInputImageContainerStyled>
                    {!loadAnimation ? 
                        <img src = {tempImage}/> 
                        :
                        <TailSpin {...tailSpinLoaderConfig}/>
                    }
                </FileInputImageContainerStyled>
            </InputContainerWrapperStyled>
        </>
    )
}

export default LeBleuForm;